/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import { RoadMapData } from "../../constants/Data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TimeImg from "../../assets/roadmap/Timeline.png";
import Spot from "../../assets/roadmap/spot-1.png";
import SpotL from "../../assets/roadmap/spot-2.png";
import Left from "../../assets/roadmap/left-1.png";
import LeftL from "../../assets/roadmap/left-2.png";
import Right from "../../assets/roadmap/right-1.png";
import RightL from "../../assets/roadmap/right-2.png";
import MobileLine from "../../assets/roadmap/MobileLine.png";

const MobileContainer = styled.div`
  width: 100%;
  margin-top: 5vw;
  position: relative;
  @media (min-width: 601px) {
    display: none;
  }
`;
const Phase = styled.div`
  position: absolute;
  top: 33vw;
  left: 85vw;
  font-size: 5vw;
  font-weight: 500;
  color: white;
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 2.5vw;
  }
`;

const PhaseB = styled(Phase)`
  top: 57vw;
  left: 140vw;
`;

const PhaseC = styled(Phase)`
  top: 50.5vw;
  left: 245vw;
`;

const PhaseD = styled(Phase)`
  top: 27.5vw;
  left: 297vw;
`;

const PhaseE = styled(Phase)`
  top: 13vw;
  left: 365vw;
`;

const InfoList = styled.ul`
  width: inherit;
  height: inherit;
  margin: 0;
`;

const InfoIndicator = styled(LazyLoadImage)`
  width: auto;
  height: auto;
  margin-left: 5vw;
`;

const InfoItem = styled.li`
  margin: 0 0 0 0;
  font-size: 3.5vw;
  color: white;
  font-weight: 400;
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 1.5vw;
  }
`;

const TimeStamp = styled(LazyLoadImage)`
  width: 8vw;
  height: auto;
  z-index: 10;
  position: absolute;
  left: 90vw;
  top: 23.5vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 5vw;
    left: 95vw;
    top: 18vw;
  }
`;

const TimeStampB = styled(TimeStamp)`
  left: 145vw;
  top: 47vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    left: 140vw;
    top: 32vw;
  }
`;
const TimeStampC = styled(TimeStamp)`
  left: 250vw;
  top: 40.5vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    left: 255vw;
    top: 28vw;
  }
`;
const TimeStampD = styled(TimeStamp)`
  left: 300vw;
  top: 17.5vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    left: 310vw;
    top: 10.5vw;
  }
`;
const TimeStampE = styled(TimeStamp)`
  left: 370vw;
  top: 3vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    top: 2vw;
  }
`;

const Information = styled.div`
  display: flex;
  width: 100%;
  height: 40vw;
  background: transparent;
  @media (min-width: 600px) and (max-width: 1366px) {
    height: 20vw;
  }
`;

const MapContainer = styled.div`
  width: 500%;
  height: 70vw;
  position: relative;
  background: url(${TimeImg});
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  transition: transform 0.5s ease-in-out;
  @media (min-width: 600px) and (max-width: 1366px) {
    height: 50vw;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-top: 0;
  }
`;

const ControlImg = styled(LazyLoadImage)`
  width: 8vw;
  height: 8vw;
  margin: 0 4vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 3vw;
    height: auto;
  }
`;

export default function MobileRoadMap({
  hover,
  setHover,
  active,
  setActive,
  ClickHandler,
}) {
  const [position, setPosition] = useState(-14);

  const switchLft = () => {
    if (active.second) {
      setPosition(position);
    } else if (active.third) {
      setPosition(position + 25);
    } else if (active.forth) {
      setPosition(position + 20);
    }
    const arr = Object.values(active);
    const index = arr.indexOf(true);
    let obj = {};
    index === 1
      ? obj = {
          first: true,
          second: false,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 2
      ? obj = {
          first: false,
          second: true,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 3
      ? obj = {
          first: false,
          second: false,
          third: true,
          forth: false,
          fifth: false,
        }
      : index === 4
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: true,
          fifth: false,
        }
      : obj = {};
    setActive(obj);
  };

  const switchRgt = () => {
    if (position === -14 && active.first) {
      setPosition(position);
    } else if (active.second) {
      setPosition(position - 25);
    } else if (active.third) {
      setPosition(position - 20);
    } else if (active.forth) {
      setPosition(position);
    }
    const arr = Object.values(active);
    const index = arr.indexOf(true);
    let obj = {};
    index === 0
      ? obj = {
          first: false,
          second: true,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 1
      ? obj = {
          first: false,
          second: false,
          third: true,
          forth: false,
          fifth: false,
        }
      : index === 2
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: true,
          fifth: false,
        }
      : index === 3
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: false,
          fifth: true,
        }
      : obj = {};
    setActive(obj);
  };

  return (
    <MobileContainer>
      <Information>
        <InfoIndicator src={MobileLine} alt="" />
        <InfoList>
          {active.second &&
            RoadMapData.Ptwo.map((item, index) => {
              return <InfoItem key={index}>{item}</InfoItem>;
            })}
          {active.first &&
            RoadMapData.Pone.map((item, index) => {
              return <InfoItem key={index}>{item}</InfoItem>;
            })}
          {active.third &&
            RoadMapData.Pthree.map((item, index) => {
              return <InfoItem key={index}>{item}</InfoItem>;
            })}
          {active.forth &&
            RoadMapData.Pfour.map((item, index) => {
              return <InfoItem key={index}>{item}</InfoItem>;
            })}
          {active.fifth &&
            RoadMapData.Pfive.map((item, index) => {
              return <InfoItem key={index}>{item}</InfoItem>;
            })}
        </InfoList>
      </Information>
      <MapContainer style={{ transform: `translateX(${position}%)` }}>
        {active.first ? (
          <TimeStamp src={SpotL} alt="" />
        ) : (
          <TimeStamp src={Spot} alt="" onClick={() => ClickHandler("A")} />
        )}
        <Phase>2021 Q2</Phase>
        {active.second ? (
          <TimeStampB src={SpotL} alt="" />
        ) : (
          <TimeStampB src={Spot} alt="" onClick={() => ClickHandler("B")} />
        )}
        <PhaseB>2021 Q3</PhaseB>
        {active.third ? (
          <TimeStampC src={SpotL} alt="" />
        ) : (
          <TimeStampC src={Spot} alt="" onClick={() => ClickHandler("C")} />
        )}
        <PhaseC>2021 Q4</PhaseC>
        {active.forth ? (
          <TimeStampD src={SpotL} alt="" />
        ) : (
          <TimeStampD src={Spot} alt="" onClick={() => ClickHandler("D")} />
        )}
        <PhaseD>2022 Q1</PhaseD>
        {active.fifth ? (
          <TimeStampE src={SpotL} alt="" />
        ) : (
          <TimeStampE src={Spot} alt="" onClick={() => ClickHandler("E")} />
        )}
        <PhaseE>2022 Q2</PhaseE>
      </MapContainer>
      <ButtonWrapper>
        {!hover.left ? (
          <ControlImg
            alt=""
            src={Left}
            onTouchStart={() => setHover({ left: true })}
            onClick={switchLft}
          />
        ) : (
          <ControlImg
            alt=""
            src={LeftL}
            onTouchEnd={() => setHover({ left: false })}
          />
        )}
        {!hover.right ? (
          <ControlImg
            alt=""
            src={Right}
            onTouchStart={() => setHover({ right: true })}
            onClick={switchRgt}
          />
        ) : (
          <ControlImg
            alt=""
            src={RightL}
            onTouchEnd={() => setHover({ right: false })}
          />
        )}
      </ButtonWrapper>
    </MobileContainer>
  );
}
