import styled from "styled-components";
import React from "react"


const Wrapper = styled.div`
    margin-top: 80px;
    width: 100%;
`;

const Container = styled.div`
    width: 60%;
    margin: auto;
    @media(max-width: 1025px){
        width: 80%;
    }
    @media(max-width: 600px){
        width: 90%;
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-weight: 700;
    @media(max-width: 1025px){
        font-size: 25px;
    }
    @media(max-width: 600px){
        font-size: 30px;
    }
`;

const Text = styled.div`
    font-size: 22px;
    margin-top: 30px;
    @media(max-width: 1025px){
        font-size: 15px;
        font-weight: 500;
    }
    @media(max-width: 600px){
        font-size: 20px;
        font-weight: 500;
    }
`;
const BBRewards = () => {
    return (
        <Wrapper>
            <Container>
                <Title>
                    Rewards
                </Title>
                <Text>
                    OIN provides bounty rewards for discovering vulnerabilities that can prevent the loss of assets, the freezing of assets, or harm to a user, in proportion to the severity and exploitability of the vulnerability. According to the terms and conditions outlined below, we provide token incentives ranging from $500 to $150,000 for eligible discoveries.
                </Text>
            </Container>
        </Wrapper>
    );
}

export default BBRewards;