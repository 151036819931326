import GlobalStyle from "../../GlobleStyle";
import React from "react";
import Nav from "../../components/Nav";
import Cover from "../../components/Cover";
import About from "../../components/About";
import Advantages from "../../components/Advantages";
import Exchange from "../../components/Exchange";
// import Team from "../../components/Team/Team";
import Partnership from "../../components/Partners/Partnership";
import Foot from "../../components/Footer/";
import RoadMap from "../../components/Roadmap/RoadMap";

function Home() {
  return (
    <>
      <GlobalStyle />
      <Nav />
      <Cover />
      <About />
      <Exchange />
      <Advantages />
      <RoadMap />
      {/* <Team /> */}
      <Partnership />
      <Foot />
    </>
  );
}

export default Home;
