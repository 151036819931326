import React from "react";
import styled from "styled-components";
import AboutIcon from "../../assets/decorator/about.png";
import { AboutData } from "../../constants/Data";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ATitle = styled.div`
  color: white;
  font-size: 2.5vw;
  font-weight: 600;
  margin-left: 5vw;
  text-shadow: 0 0 10px #4dcdff;
  position: relative;
  width: max-content;
  height: max-content;
  @media (max-width: 600px) {
    font-size: 8vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
    margin-left: 2vw;
  }
`;

export const Gradient = styled.span`
  position: absolute;
  height: 0.8vw;
  width: 100%;
  bottom: 2px;
  z-index: -1;
  background: linear-gradient(90deg, #4760ff, #0dccff);
  left: 0;
  @media (max-width: 600px) {
    bottom: 8px;
    height: 2.5vw;
  }
`;

const Strong = styled.strong`
  margin: 0;
  font-weight: 600;
  color: white;
  font-size: 1.3vw;
  @media (max-width: 600px) {
    font-size: 4vw;
    font-weight: 400;
  }
`;

const AIcon = styled(LazyLoadImage)`
  height: auto;
  width: 35%;
  margin-right: 2vw;
  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 50%;
  }
`;

const MobileIcon = styled(LazyLoadImage)`
  @media (min-width: 600px) {
    display: none;
  }
  width: 80%;
  margin-left: 10%;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25vw;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const TextWraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 100%;
    padding-right: 5px;
  }
`;

const ContentTitle = styled.h1`
  color: white;
  text-align: left;
  margin-top: 50px;
  margin-left: 5vw;
  font-size: 1.5vw;
  @media (max-width: 600px) {
    font-size: 5vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 2.5vw;
    margin: 2vw 0 2vw 2vw;
  }
`;

const Content = styled.p`
  color: white;
  font-size: 1.1vw;
  margin-left: 5vw;
  text-align: left;
  margin-bottom: 10px;
  line-height: 2.1vw;
  font-weight: 400;
  @media (max-width: 600px) {
    font-size: 4vw;
    line-height: 25px;
    font-weight: 400;
    line-height: 7vw;
    text-align: left;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 1.5vw;
    line-height: 2.5vw;
    margin: 0 0 0 2vw;
  }
`;

const About = () => {
  return (
    <>
      <ContentWrapper id="Scroll-1">
        <AIcon src={AboutIcon} alt="" />
        <TextWraper>
          <ATitle>
            About Us
            <Gradient />
          </ATitle>
          <MobileIcon alt="" src={AboutIcon} />
          <ContentTitle>{AboutData.Title}</ContentTitle>
          <Content>
            <Strong>OIN</Strong> {AboutData.Content}
          </Content>
          <Content>{AboutData.Content2}</Content>
          <Content>{AboutData.Content3}</Content>
        </TextWraper>
      </ContentWrapper>
    </>
  );
};

export default About;
