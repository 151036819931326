import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import BugBounty from "./pages/bugbounty/bugbounty";
function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bug_bounty" element={<BugBounty />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
