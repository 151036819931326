/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import { RiArrowDownSLine } from "react-icons/ri";

const MenuItem = styled.div`
  color: #1312cf;
  font-size: 4vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 10vw;
  padding: ${(props) =>
    props.last ? `6vw 0 0 0` : props.first ? `4vw 0 0 0` : `8vw 0 0 0`};
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 2vw;
    padding: ${(props) => (props.last ? `2vw 0 0 0` : `4vw 0 0 0`)};
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10vw;
`;

const SubItem = styled.span`
  color: #1312cf;
  font-size: 4vw;
  font-weight: bold;
  padding: 3.5vw 0 0 0;
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 2vw;
    padding: 1vw 0;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  height: 20vw;
  border-bottom: ${(props) => (props.border ? `1px solid #D3D3D3` : 0)};
  @media (min-width: 600px) and (max-width: 1366px) {
    height: 11vw;
  }
`;

export default function MobileSub({ index, item }) {
  const [expand, setExpand] = useState(false);
  const showExpand = () => {
    setExpand(!expand);
  };
  const menuClickHandler = (dropdown, link) => {
    if (dropdown) {
      showExpand();
    } else if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <SubWrapper key={index} border={item.border}>
      <MenuItem
        last={item.last}
        onClick={() => menuClickHandler(item.dropdown, item.link)}
        first={item.first}
      >
        {item.title}
        {item.dropdown ? (
          <RiArrowDownSLine
            style={{ color: "#1312cf", width: "3vw", height: "auto" }}
          />
        ) : null}
      </MenuItem>
      <SubMenu>
        {expand &&
          item.dropdown &&
          item.dropdown.map((link) => {
            return (
              <SubItem key={link.id} onClick={() => window.open(link.link)}>
                {link.title}
              </SubItem>
            );
          })}
      </SubMenu>
    </SubWrapper>
  );
}
