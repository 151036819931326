import styled from "styled-components";
import React from "react"


const Wrapper = styled.div`
    margin-top: 80px;
    width: 100%;
`;

const Container = styled.div`
    width: 60%;
    margin: auto;
    @media(max-width: 1025px){
        width: 80%;
    }
    @media(max-width: 600px){
        width: 90%;
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-weight: 700;
    @media(max-width: 1025px){
        font-size: 25px;
    }
    @media(max-width: 600px){
        font-size: 30px;
    }
`;

const Text = styled.div`
    font-size: 22px;
    margin-top: 30px;
    @media(max-width: 1025px){
        font-size: 15px;
        font-weight: 500;
    }
    @media(max-width: 600px){
        font-size: 20px;
        font-weight: 500;
    }
`;

const LI = styled.li`
    margin-top: 20px;
`;
const BBTerms = () => {
    return (
        <Wrapper>
            <Container>
                <Title>
                    Terms and Conditions
                </Title>
                <Text>
                    What you must do to be eligible for the Bug Bounty Program are as follows:
                    <ul>
                        <LI>Submit all bug bounty disclosures privately and directly to the OIN team at <strong>security@oin.finance</strong>, and only disclose the vulnerability with our team members</LI>
                        <LI>Include sufficient information in your disclosure to allow our engineers to quickly reproduce, understand, and resolve the vulnerability
                        </LI>
                        <LI>Avoid violating the privacy of others, disrupting our systems, destroying data, or harming user experience. Disclosures must not exploit the vulnerability unless in a negligible amount</LI>
                        <LI>Maintain the confidentiality of any discovered vulnerabilities until they are fixed by OIN</LI>
                        <LI>Recognize your contribution to improving our security if you are the first to report a unique vulnerability, and your report triggers a code or configuration change</LI>
                        <LI>The disclosure must include clear and concise steps to reproduce the discovered vulnerability in either written or video format. OIN will follow up promptly with acknowledgement of the disclosure</LI>
                        <LI>If other disclosures provide additional information based on the first report, or if the vulnerability is particularly serious, the OIN team will decide whether or not to issue the reward at its discretion</LI>
                    </ul>
                </Text>
            </Container>
        </Wrapper>
    );
}

export default BBTerms;