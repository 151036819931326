/* eslint-disable no-undef */
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ConverData } from "../../constants/Data";
import axios from "axios";
import BIcon from "../../assets/icons/icon-rocket.png";
import Astro1 from "../../assets/decorator/Astrounant1.png";
import Astro2 from "../../assets/decorator/Astrounant2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Astrounat = styled(LazyLoadImage)`
  height: auto;
  position: absolute;
  animation: mymove 2s infinite;
  animation-direction: alternate;
  right: 10%;
  @keyframes mymove {
    from {
      top: 50%;
      width: 6%;
    }
    to {
      top: 52%;
      width: 7%;
    }
  }

  @keyframes mymbmove {
    from {
      top: 35%;
      width: 13%;
    }
    to {
      top: 37%;
      width: 15%;
    }
  }
  @media (max-width: 600px) {
    width: 13%;
    right: 8%;
    animation: mymbmove 4s infinite;
    animation-direction: alternate;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    display: none;
  }
`;

const AstrounatB = styled(Astrounat)`
  left: 60%;
  @media (max-width: 600px) {
    width: 15%;
    left: 25%;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 2vw;
  position: relative;
  @media (max-width: 600px) {
    margin-top: 9vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-top: 8vw;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  color: white;
  font-size: 2.5vw;
  margin-left: 10vw;
  text-shadow: 0 0 10px #4dcdff;
  @media (max-width: 600px) {
    font-size: 6vw;
    margin-left: 5vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-left: 5vw;
    font-size: 3vw;
  }
`;

const CoverTitle = styled(Title)`
  font-size: 3vw;
  @media (max-width: 600px) {
    font-size: 6vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
  }
`;

const MobileInfo = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
  margin-top: 43vh;
  display: flex;
  flex-direction: column;
  margin-left: 7vw;
`;

const MobileItem = styled.div`
  display: flex;
  color: white;
  font-size: 4vw;
  align-items: center;
  @media (min-width: 600px) {
    display: none;
  }
`;

const MobileText = styled.p`
  color: white;
  font-size: 6vw;
  margin: ${(props) => props.m};
`;

// const Info = styled.div`
//   margin-left: 10vw;
//   display: flex;
//   align-items: center;
//   height: 4vw;
//   margin-top: 30px;
//   @media (max-width: 600px) {
//     display: none;
//   }
//   @media (min-width: 600px) and (max-width: 1366px) {
//     margin-left: 5vw;
//   }
// `;

const InfoSub = styled.div`
  margin-left: 10vw;
  margin-top: 2vw;
  display: flex;
  align-items: center;
  height: max-content;
  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-left: 5vw;
    margin-top: 30px;
  }
`;

const InfoSubTitle = styled.p`
  margin: 0;
  font-size: ${(props) => props.size};
  color: #ffffff;
  font-weight: ${(props) => props.weight};
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: ${(props) => props.padSize};
  }
`;

const InfoType = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 3vw;
  }
`;

const InfoValue = styled.p`
  color: white;
  font-weight: bold;
  margin: 0 0 0 2vw;
  font-size: 2.5vw;
  @media (max-width: 600px) {
    font-size: 5vw;
    margin-left: 5vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 3vw;
  }
`;

const Stake = styled.div`
  cursor: pointer;
  outline: none;
  margin-left: 10vw;
  margin-top: 2vw;
  border: 0;
  color: white;
  background: linear-gradient(90deg, #4760ff, #0dccff);
  transition: transform 0.1s ease-in;
  transition: background-color 0.1s ease-in-out;
  border-radius: 19px;
  height: 40px;
  font-weight: bold;
  width: 12vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1vw;
  &:active {
    transform: scale(1.05);
  }
  @media (max-width: 600px) {
    margin: 10vw 0 0 25%;
    width: 50vw;
    cursor: default;
    border-radius: 33px;
    height: 40px;
    font-size: 4vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin: 5vw 0 0 5vw;
    width: 22vw;
    height: 5vw;
    font-size: 2vw;
  }
`;

const ButtonIcon = styled.img`
  height: 1vw;
  width: 1vw;
  margin-left: 1vw;
  @media (max-width: 600px) {
    height: auto;
    width: 5vw;
    margin-left: 10px;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-left: 2vw;
    width: 3vw;
    height: auto;
  }
`;

const Cover = () => {
  const [value, setValue] = useState("0");
  useEffect(() => {
    FetchData();
  }, [value]);

  const FetchData = async () => {
    let [oinValue, harmony_stake, harmony_stable, aurora_stake, aurora_stable] =
      await Promise.all([
        axios.get(process.env.REACT_APP_API),
        axios.get(process.env.REACT_APP_HARMONY_STAKE),
        axios.get(process.env.REACT_APP_HARMONY_STABLE),
        axios.get(process.env.REACT_APP_WNEAR_STAKE),
        axios.get(process.env.REACT_APP_WNEAR_STABLE),
      ]);
    oinValue = parseFloat(oinValue.data.replace(/,/g, ""));
    const res =
      oinValue +
      harmony_stake.data +
      harmony_stable.data +
      aurora_stake.data +
      aurora_stable.data;
    setValue(
      new Intl.NumberFormat("en-NZ", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(res)
    );
  };
  return (
    <Container>
      <Astrounat alt="" src={Astro2} />
      <AstrounatB alt="" src={Astro1} />
      <CoverTitle>{ConverData.Title}</CoverTitle>
      <CoverTitle>{ConverData.Title2}</CoverTitle>
      <CoverTitle>{ConverData.Title3}</CoverTitle>
      <MobileInfo>
        <MobileItem>
          {ConverData.MobileIssued2}
          <MobileText m="0 0 0 20px"> ${value}</MobileText>
        </MobileItem>
      </MobileInfo>
      <InfoSub>
        <InfoType>
          <InfoSubTitle weight="400" size="1.2vw" padSize="2vw">
            {ConverData.Locked}
          </InfoSubTitle>
          <InfoSubTitle weight="200" size=".8vw" padSize="1.6vw">
            {ConverData.Locked2}
          </InfoSubTitle>
        </InfoType>
        <InfoValue>${value}</InfoValue>
      </InfoSub>
      <Stake onClick={() => window.open("https://v3.oin.finance/", "_blank")}>
        {ConverData.Button} <ButtonIcon src={BIcon} alt="" />
      </Stake>
    </Container>
  );
};

export default Cover;
