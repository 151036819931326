/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import React, { useState } from 'react';
import { NavData } from '../../constants/Data';
import Oin from '../../assets/logo/logo.png';
import { RiArrowDownSLine } from 'react-icons/ri';
import Dropdown from './DropDown';
import { IoIosMenu } from 'react-icons/io';
import MobileMenu from './MobileMenu';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  height: 8vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
  @media (max-width: 1366px) {
    height: max-content;
    z-index: 10;
  }
`;

const Logo = styled.img`
  width: 8vw;
  margin-left: 10vw;
  @media (max-width: 600px) {
    width: 20vw;
    margin-left: 5vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 12vw;
    height: auto;
    margin-left: 5vw;
  }
`;

const MenuList = styled.div`
  height: 100%;
  display: flex;
  margin-right: 8vw;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1366px) {
    display: none;
  }
`;

const MenuItem = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1vw;
  margin: 0 1vw;
  align-items: center;
  display: flex;
  width: max-content;
  cursor: pointer;
  position: relative;
`;

const MobileToggle = styled(IoIosMenu)`
  color: white;
  margin: 0 5vw 0 0;
  height: 10vw;
  width: 8vw;
  @media (min-width: 1367px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 4vw;
    height: auto;
  }
`;

const Nav = () => {
  const [hover, setHover] = useState({
    doc: false,
    community: false,
    lp: false,
    bridge: false,
    dao: false,
    bugBounty: false,
  });
  const enterHandler = (title) => {
    let obj = {};
    title.includes('Docs')
      ? obj = { doc: true }
      : title.includes('Community')
      ? obj = { community: true }
      : title.includes('Staking')
      ? obj = { lp: true }
      : title.includes('Bridge')
      ? obj = { bridge: true }
      : title.includes('OINDAO')
      ? obj = { dao: true }
      : title.includes('Bounty Pro')
      ? obj = { bugBounty: true }
      : obj = {};
    setHover(obj);
  };

  const leaveHandler = (title) => {
    let obj = {}
    title.includes('Docs')
      ? obj = { doc: false }
      : title.includes('Community')
      ? obj = { community: false }
      : title.includes('Staking')
      ? obj = { lp: false }
      : title.includes('Bridge')
      ? obj = { bridge: false }
      : title.includes('OINDAO')
      ? obj = { dao: false }
      : title.includes('Bounty Pro')
      ? obj = { bugBounty: false }
      : obj = {};
    setHover(obj)
  };

  const ClickHandler = () => {
    document.getElementById('mobileMenu').style.right = '0%';
  };

  const onLinkeHandler = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  return (
    <Wrapper>
      <Logo src={Oin} alt="" />
      <MobileToggle onClick={ClickHandler} />
      <MobileMenu />
      <MenuList>
        {NavData.map((item) => {
          if(item.title == "Bug Bounty"){
            return (
            
              <Link to={{pathname: item.link}} style={{ textDecoration: 'none' }}>
                <MenuItem
                  key={item.id}
                  onMouseOver={() => enterHandler(item.title)}
                  // onClick={() => {
                  //   onLinkeHandler(item.link);
                  // }}
                  >
                  {item.title}
                  {item.dropdown ? (
                    <>
                      <RiArrowDownSLine
                        className="Navbar-svg"
                        key={item.title}
                        style={{
                          color: '#ffff',
                          width: '1.5vw',
                          height: 'auto',
                          marginTop: `.1vw`,
                        }}
                      />
                      <Dropdown
                        title={item.title}
                        hover={hover}
                        leave={leaveHandler}
                      />
                    </>
                  ) : null}
                </MenuItem>
              </Link>
            );
          }
          else{
            return (
                <MenuItem
                  key={item.id}
                  onMouseOver={() => enterHandler(item.title)}
                  onClick={() => {
                    onLinkeHandler(item.link);
                  }}
                  >
                  {item.title}
                  {item.dropdown ? (
                    <>
                      <RiArrowDownSLine
                        className="Navbar-svg"
                        key={item.title}
                        style={{
                          color: '#ffff',
                          width: '1.5vw',
                          height: 'auto',
                          marginTop: `.1vw`,
                        }}
                      />
                      <Dropdown
                        title={item.title}
                        hover={hover}
                        leave={leaveHandler}
                      />
                    </>
                  ) : null}
                </MenuItem>
            );
          }
        }
        
        )}
      </MenuList>
    </Wrapper>
  );
};

export default Nav;
