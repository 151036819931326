import React from 'react';
import styled from 'styled-components';
import { MobileNav } from '../../constants/Data';
import { AiOutlineClose } from 'react-icons/ai';
import MobileSub from './MobileSub';

const MenuWrapper = styled.div`
  position: absolute;
  top: -21px;
  right: -50%;
  transition: all 0.5s ease-in-out;
  background: white;
  height: 200vh;
  width: 50%;
  @media (min-width: 1367px) {
    display: none;
  }
`;

const CloseMenu = styled(AiOutlineClose)`
  width: 5vw;
  height: auto;
  background: #1312cf;
  color: white;
  border-radius: 20px;
  position: absolute;
  top: 5vw;
  right: 2vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    cursor: pointer;
    width: 3vw;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20vw;
  @media (min-width: 600px) and (max-width: 1366px) {
    margin-top: 10vw;
  }
`;

export default function MobileMenu() {
  const closeHandler = () => {
    document.getElementById('mobileMenu').style.right = '-50%';
  };
  return (
    <MenuWrapper id="mobileMenu">
      <CloseMenu onClick={closeHandler} />
      <MenuList>
        {MobileNav.map((item, index) => {
          return <MobileSub key={index} item={item} index={index} />;
        })}
      </MenuList>
    </MenuWrapper>
  );
}
