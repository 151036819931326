import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FootData } from '../../constants/Data';
import { Link } from 'react-scroll';
// import Wechat from "../../assets/icons/WeChat.png";
// import QrCodeImg from '../../assets/logo/qrcode.png';
import BBFootBackground from "../../assets/backgrounds/BBFoot.png"
const Container = styled.div`
  background: url(${BBFootBackground});
  background-size: cover;
  width: 100%;
  margin-top: 5vw;
  padding-top: 1vw;
  height: 5vw;
  overflow: hidden;
  @media (max-width: 600px) {
    height: max-content;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    height: max-content;
    margin-bottom: 20px;
  }
`;

const Contact = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const IconGroup = styled.div`
  margin: 0 15vw 0 0;
  @media (max-width: 600px) {
    margin: 2rem 0 10px 0;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin: 0 5vw 0 0;
  }
`;

const Social = styled(LazyLoadImage)`
  width: 2vw;
  height: auto;
  cursor: pointer;
  margin: 0 0.5vw;
  @media (max-width: 600px) {
    width: 6vw;
    margin: 0 3vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 3vw;
    margin: 0 1vw;
  }
`;

const FootNav = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    margin: 0;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const CopyRight = styled.div`
  color: white;
  margin-left: 15vw;
  font-size: 1vw;
  @media (max-width: 600px) {
    font-size: 3vw;
    margin-left: 0;
    margin-top: 3vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 1.5vw;
    margin-left: 5%;
  }
`;

const Nav = styled.div`
  display: flex;
  margin-left: 15vw;
  @media (max-width: 600px) {
    width: 90%;
    flex-wrap: wrap;
    margin: 3vw 0 0 0;
    justify-content: center;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin: 0 0 0 5%;
  }
`;

const NavItem = styled(Link)`
  color: white;
  cursor: pointer;
  margin: 0 1vw 0 0;
  font-size: 1.1vw;
  @media (max-width: 600px) {
    font-size: 3.5vw;
    margin: 1.5vw 1vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 1.5vw;
  }
`;

export default function BBFoot() {
  return (
    <Container>
      <FootNav>
        <Nav>
          {FootData.NavItem.map((item, index) => {
            return (
              <NavItem
                smooth={true}
                duration={1000}
                to={item.link}
                key={`FootNac${index}`}>
                {item.title}
              </NavItem>
            );
          })}
        </Nav>
        <IconGroup>
          {/* {hover && <QRcode alt="" src={QrCodeImg} />} */}
          {FootData.Social.map((item, index) => {
            return (
              <Social
                key={index}
                alt=""
                src={item.icon}
                onClick={() => {
                  window.open(item.link, '_blank');
                }}
              />
            );
          })}
          {/* <Social
            alt=""
            src={Wechat}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          /> */}
        </IconGroup>
      </FootNav>
      <Contact>
        <CopyRight>{FootData.CopyRight}</CopyRight>
      </Contact>
    </Container>
  );
}
