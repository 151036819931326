import React from "react";
import styled from "styled-components";
import Ex from "../../assets/decorator/starlayer.png";
import Logo1 from "../../assets/decorator/starlogo1.png";
import Logo2 from "../../assets/decorator/starlogo2.png";
import Logo3 from "../../assets/decorator/starlogo3.png";
import Logo4 from "../../assets/decorator/starlogo4.png";
import Logo5 from "../../assets/decorator/starlogo5.png";
import { Gradient } from "../About/About";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15vw;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  margin: 0 0 50px 0;
  color: #ffffff;
  font-size: 2.5vw;
  font-weight: 600;
  text-shadow: 0 0 10px #4dcdff;
  height: max-content;
  width: max-content;
  position: relative;
  @media (max-width: 600px) {
    font-size: 8vw;
    margin-bottom: 20px;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
    margin-bottom: 10px;
  }
`;

const ExImg = styled(LazyLoadImage)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ExDescription = styled.div`
  width: 40%;
  display: flex;
  padding-left: 10%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    margin-left: 5%;
    padding: 0;
    width: 90%;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 50%;
    padding-left: 5%;
  }
`;
const TextContent = styled.div`
  color: white;
  font-size: 1.1vw;
  line-height: 2.1vw;
  font-weight: 400;
  width: 90%;
  margin: 5px 0;
  @media (max-width: 600px) {
    font-size: 4vw;
    line-height: 7vw;
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 100%;
    font-size: 1.5vw;
    line-height: 2.5vw;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 50%;
  height: max-content;
  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileImgContainer = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
  position: relative;
  height: max-content;
  width: 100%;
`;

const ClickLogo = styled(LazyLoadImage)`
  position: absolute;
  width: 7%;
  height: auto;
  cursor: pointer;
  transition: all 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;

export default function Exchange() {
  return (
    <Container id="Scroll-4">
      <ExDescription>
        <Title>
          OIN Network <Gradient />
        </Title>
        <MobileImgContainer>
          <ExImg src={Ex} alt="" effct="blur" />
          <ClickLogo
            alt=""
            effct="blur"
            src={Logo1}
            style={{
              left: "56%",
              top: "60%",
              transform: "rotatez(-35deg)",
            }}
            onClick={() => window.open("https://ethereum.org/en/", "_blank")}
          />
          <ClickLogo
            alt=""
            effct="blur"
            src={Logo2}
            style={{
              left: "35.5%",
              top: "60.5%",
              transform: "rotatez(-90deg)",
            }}
            onClick={() => window.open("https://www.avax.network/", "_blank")}
          />
          <ClickLogo
            alt=""
            effct="blur"
            src={Logo3}
            style={{
              left: "30.5%",
              top: "40.7%",
              transform: "rotatez(110deg)",
            }}
            onClick={() => window.open("https://www.harmony.one/cn", "_blank")}
          />
          <ClickLogo
            alt=""
            effct="blur"
            src={Logo4}
            style={{
              left: "61.5%",
              top: "40.5%",
              transform: "rotatez(-110deg)",
            }}
            onClick={() => window.open("https://polkadot.network/", "_blank")}
          />
          <ClickLogo
            alt=""
            effct="blur"
            src={Logo5}
            style={{ left: "46%", top: "28.7%", transform: "rotatez(0deg)" }}
            onClick={() => window.open("https://near.org/", "_blank")}
          />
        </MobileImgContainer>
        <TextContent>
          Much like the vast and infinite possibilities of the universe, our
          OINDAO is building a stablecoin universe in a multi-chain strategy,
          building crypto-backed stablecoins for each of the public chains
          either natively or through EVMs.
        </TextContent>
        <TextContent>
          In each ecosystem, represented here through the constellations, the
          stablecoins built through our platform will be natively backed by
          their respective Tokens and through the tokens of its ecosystem
          projects allowing different listings to be empowered with
          ecosystem-specific utilities.
        </TextContent>
        <TextContent>
          The center of it all, the center of the stablecoin universe, will be
          the OINDAO platform, connecting the endless use cases and value
          propositions through a seamless platform.
        </TextContent>
      </ExDescription>
      <ImgContainer>
        <ExImg src={Ex} alt="" effct="blur" />
        <ClickLogo
          alt=""
          effct="blur"
          src={Logo1}
          style={{ left: "55.5%", top: "60.5%", transform: "rotatez(-35deg)" }}
          onClick={() => window.open("https://ethereum.org/en/", "_blank")}
        />
        <ClickLogo
          alt=""
          effct="blur"
          src={Logo2}
          style={{ left: "37%", top: "60%", transform: "rotatez(35deg)" }}
          onClick={() => window.open("https://www.avax.network/", "_blank")}
        />
        <ClickLogo
          alt=""
          effct="blur"
          src={Logo3}
          style={{ left: "30.5%", top: "41%", transform: "rotatez(110deg)" }}
          onClick={() => window.open("https://www.harmony.one/cn", "_blank")}
        />
        <ClickLogo
          alt=""
          effct="blur"
          src={Logo4}
          style={{ left: "61.5%", top: "41%", transform: "rotatez(-110deg)" }}
          onClick={() => window.open("https://polkadot.network/", "_blank")}
        />
        <ClickLogo
          alt=""
          effct="blur"
          src={Logo5}
          style={{ left: "46%", top: "29%", transform: "rotatez(0deg)" }}
          onClick={() => window.open("https://near.org/", "_blank")}
        />
      </ImgContainer>
    </Container>
  );
}
