/* eslint-disable react/prop-types */
import styled from "styled-components";
import React from "react";

const data = {
  lp: [
    { name: "Oinstake", link: "https://dao.oin.finance/" },
    { name: "Uniswap", link: " https://oin.finance/page/" },
    { name: "Pancake", link: "https://oin.finance/bscpage/" },
  ],
  doc: [{ name: "OINDAO Docs", link: "https://oin-finance.gitbook.io/925/" }],
  community: [
    {
      name: "Twitter",
      link: "https://twitter.com/financeoin?s=21",
    },
    { name: "Medium", link: "https://medium.com/oin-finance" },
    { name: "Telegram", link: "https://t.me/OIN_Finance_Eng" },
    { name: "Discord", link: "https://discord.com/invite/wHgckxGYje" },
  ],
  bridge: [
    { name: "BSC", link: "https://bg.oin.finance/" },
    { name: "NEAR", link: "https://rainbowbridge.app/transfer" },
    { name: "HARMONY", link: "https://bridge.harmony.one/erc20" },
  ],
};

const Container = styled.div`
  background: #030555;
  position: absolute;
  top: 1.5vw;
  width: 8vw;
  display: flex;
  box-shadow: 0 0 4px #4dcdff;
  flex-direction: column;
  cursor: default;
  border-radius: 3px;
  z-index: 10;
`;

const Item = styled.div`
  color: white;
  height: 30px;
  font-size: 14px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  letter-spacing: 0.05rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Dropdown = ({ title, hover, leave }) => {
  return title.includes("Docs") ? (
    hover.doc ? (
      <Container onMouseLeave={() => leave(title)}>
        {data.doc.map((item, index) => {
          return (
            <Item key={index} onClick={() => window.open(item.link, "_blank")}>
              {item.name}
            </Item>
          );
        })}
      </Container>
    ) : null
  ) : title.includes("Community") ? (
    hover.community ? (
      <Container onMouseLeave={() => leave(title)}>
        {data.community.map((item, index) => {
          return (
            <Item key={index} onClick={() => window.open(item.link, "_blank")}>
              {item.name}
            </Item>
          );
        })}
      </Container>
    ) : null
  ) : title.includes("Staking") ? (
    hover.lp ? (
      <Container onMouseLeave={() => leave(title)}>
        {data.lp.map((item, index) => {
          return (
            <Item key={index} onClick={() => window.open(item.link, "_blank")}>
              {item.name}
            </Item>
          );
        })}
      </Container>
    ) : null
  ) : title.includes("Bridge") ? (
    hover.bridge ? (
      <Container onMouseLeave={() => leave(title)}>
        {data.bridge.map((item, index) => {
          return (
            <Item key={index} onClick={() => window.open(item.link, "_blank")}>
              {item.name}
            </Item>
          );
        })}
      </Container>
    ) : null
  ) : null;
};

export default Dropdown;
