import { Title } from "../Cover/Cover";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { AdvData } from "../../constants/Data";
import { Gradient } from "../About/About";
import MoreIcon from "../../assets/icons/icon6.png";

const AdvTitle = styled(Title)`
  width: max-content;
  height: max-content;
  position: relative;
  margin-top: 20vw;
  @media (max-width: 600px) {
    font-size: 8vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
  }
`;

const Container = styled.div`
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15vw;
  -webkit-column-gap: 9%;
  column-gap: 9%;
  @media (max-width: 600px) {
    margin-left: 5vw;
    width: 90%;
    margin-top: 5vw;
    -webkit-column-gap: 3%;
    column-gap: 3%;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin: 0 0 0 5vw;
    width: 90%;
  }
`;

const AdIcon = styled(LazyLoadImage)`
  margin-top: 30px;
  width: 65px;
  height: 60px;
  background: transparent;
  @media (max-width: 600px) {
    width: 13vw;
    height: 12vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 5vw;
    height: auto;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(1, 20, 81);
  width: 18vw;
  margin-top: 50px;
  @media (max-width: 600px) {
    width: 48%;
    margin: 0 0 10vw 0;
    background: rgb(10, 164, 255, 0.1);
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    margin: 5vw 0vw 0 0;
    width: 24.5vw;
  }
`;

const Description = styled.p`
  color: white;
  font-weight: 600;
  font-size: 1.2vw;
  width: max-content;
  @media (max-width: 600px) {
    font-size: 3.5vw;
  }
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 1.5vw;
    line-height: 2.5vw;
  }
`;

const SubDes = styled.div`
  color: white;
  font-size: 0.8vw;
  width: 85%;
  margin: 0 0 20px 0;
  font-weight: 400;
  text-align: center;
  line-height: 1.3vw;
  @media (max-width: 600px) {
    font-size: 3vw;
    line-height: 4.5vw;
  }
`;

const More = styled.div`
  background: transparent;
  width: 18vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: 49%;
  }
`;

const MoreImg = styled(LazyLoadImage)`
  width: 4vw;
  height: auto;
  @media (max-width: 600px) {
    width: 12vw;
  }
`;

export default function Advantages() {
  return (
    <>
      <AdvTitle id="Scroll-2">
        <Gradient />
        Advantages
      </AdvTitle>
      <Container>
        {AdvData.map((item) => {
          return (
            <Block key={item.id}>
              <AdIcon src={item.icon} alt="" style={item.style} />
              <Description>{item.title}</Description>
              <SubDes>{item.description}</SubDes>
            </Block>
          );
        })}
        <More>
          <MoreImg src={MoreIcon} alt="" />
        </More>
      </Container>
    </>
  );
}
