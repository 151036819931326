/* eslint-disable no-undef */
import styled from "styled-components";
import background from "../../assets/backgrounds/bbbanner.png";
import background_mobile from "../../assets/backgrounds/bbbanner_mobile.png";
import background_pad from "../../assets/backgrounds/bbbanner_pad.png";
const Wrapper = styled.div`
  width: 100%;
  height: 60vh;
  margin-top: -8vw;
  position: relative;
  background: url(${background});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  @media (max-width: 1025px) {
    background: url(${background_pad});
    background-size: cover;
  }

  @media (max-width: 600px) {
    align-items: unset;
    margin-top: -10vw;
    padding-left: 30px;
    background: url(${background_mobile});
    background-size: cover;
    background-position: 80% 50%;
    background-repeat: no-repeat;
  }
`;

const Title = styled.div`
  text-shadow: 0 0 10px #4dcdff;
  position: relative;
  z-index: 4;
  font-size: 3.5vw;
  color: white;
  margin-top: 6vw;
  font-weight: 600;
  @media (max-width: 1025px) {
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 6vw;
  }
`;

const SubTitle = styled.div`
  font-size: 1.5vw;
  color: white;
  font-weight: 500;
  margin-top: 3vw;
  @media (max-width: 1025px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 4vw;
  }
`;

const BBBanner = () => {
  return (
    <Wrapper>
      <Title>OIN Bug Bounty Program</Title>
      <br />
      <SubTitle>Find bugs, submit, earn</SubTitle>
    </Wrapper>
  );
};

export default BBBanner;
