import { createGlobalStyle } from "styled-components";
import background from "./assets/backgrounds/background.png";
import Mobilebackground from "./assets/backgrounds/background-mobile.png";
import Tabbackground from "./assets/backgrounds/background-tab.jpg";

const GlobalStyle = createGlobalStyle`
    html, body{
        width:100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
        overflow-x: hidden;
    }
    body{
        background: #030343 url(${background});
        background-position: 0px -10vw;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        user-select: none;
    
    @media (max-width: 600px) {
        background: #030343 url(${Mobilebackground});
        background-position: 100% -6vh;
        background-size: cover;
        background-repeat: no-repeat;

    }
    @media (min-width:600px) and (max-width:1367px){
        background: #030343 url(${Tabbackground});
        background-position: 0px -70vw;
        background-size: cover;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    }
`;

export default GlobalStyle;
