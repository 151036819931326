import React from "react";
import { Title } from "../Cover/Cover";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PartnershipData } from "../../constants/Data";
import styled from "styled-components";
import MobilePartner from "./MobilePartner";
import { Gradient } from "../About/About";

const ParTitle = styled(Title)`
  position: relative;
  width: max-content;
  height: max-content;
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
  }
  @media (max-width: 600px) {
    font-size: 8vw;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    display: none;
  }
`;

const GTitle = styled.div`
  width: ${(props) => props.w};
  display: flex;
  align-items: center;
  height: 4vw;
  color: white;
  font-size: 1.5vw;
  background: rgba(255, 255, 255, 0.05);
  margin: 50px 0 0 15%;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: ${(props) => props.tabW};
    height: 7vw;
    font-size: 2vw;
    margin-left: 5%;
  }
`;

const ExoGrid = styled.div`
  display: grid;
  width: 70%;
  height: 7vw;
  grid-gap: 0;
  background: transparent;
  border-radius: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 100%;
  margin-left: 15%;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 90%;
    height: 9vw;
    margin-left: 5%;
  }
`;

const EcoGrid = styled.div`
  display: grid;
  width: 70%;
  height: 30vw;
  grid-gap: 0;
  margin-left: 15%;
  background: transparent;
  border-radius: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 25%;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 90%;
    height: 40vw;
    margin-left: 5%;
  }
`;

const InvestorGrid = styled.div`
  display: grid;
  width: 70%;
  height: 16vw;
  margin-left: 15%;
  background: transparent;
  border-radius: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 50%;
  @media (min-width: 600px) and (max-width: 1366px) {
    width: 90%;
    height: 20vw;
    margin-left: 5%;
  }
`;

const Logo = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  filter: brightness(100);
  background: ${(props) =>
    props.transparent ? `transparent` : `rgba(255, 255, 255, 0.05)`};
`;

const ExLogo = styled(Logo)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    width: 110%;
    height: 110%;
  }
`;

export default function Partnership() {
  const exchangeHandler = (id) => {
    if (id.includes("1")) {
      window.open(
        "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9aeb50f542050172359a0e1a25a9933bc8c01259",
        "_blank"
      );
    } else if (id.includes("2")) {
      window.open(
        "https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658E64FFcF40D240A43D52CA9342140316Ae44fA",
        "_blank"
      );
    } else {
      window.open(
        "https://ascendex.com/en/staking/investment-product-details/OIN-S",
        "_blank"
      );
    }
  };
  return (
    <>
      <ParTitle id="Scroll-6" style={{ marginTop: `20vw` }}>
        <Gradient />
        Partnerships
      </ParTitle>
      <MobilePartner />
      <Container>
        <GTitle w="42%" tabW="54%">
          <p style={{ marginLeft: `2vw` }}>Exchanges</p>
        </GTitle>
        <ExoGrid>
          {PartnershipData.Exchanges.map((item) => {
            return (
              <ExLogo
                src={item.logo}
                alt=""
                key={item.id}
                transparent={item.transparent}
                onClick={() => exchangeHandler(item.id)}
              />
            );
          })}
        </ExoGrid>
        <GTitle w="70%" tabW="90%">
          <p style={{ marginLeft: `2vw` }}>Investors</p>
        </GTitle>
        <InvestorGrid>
          {PartnershipData.Investors.map((item) => {
            return (
              <Logo
                src={item.logo}
                alt=""
                key={item.id}
                transparent={item.transparent}
              />
            );
          })}
        </InvestorGrid>
        <GTitle w="70%" tabW="90%">
          <p style={{ marginLeft: `2vw` }}>Ecosystem</p>
        </GTitle>
        <EcoGrid>
          {PartnershipData.Ecosystem.map((item) => {
            return (
              <Logo
                src={item.logo}
                alt=""
                key={item.id}
                transparent={item.transparent}
              />
            );
          })}
        </EcoGrid>
      </Container>
    </>
  );
}
