import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Grid, Flex } from "@chakra-ui/react";
import { MobilePartnershipData } from "../../constants/Data";

const Container = styled.div`
  margin-top: 5vw;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 600px) {
    display: none;
  }
`;

const Logo = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  filter: brightness(100);
  background: ${(props) =>
    props.transparent ? `transparent` : `rgba(255, 255, 255, 0.05)`};
`;

export default function MobilePartner() {
  const exchangeHandler = (id) => {
    if (id.includes("1")) {
      window.open(
        "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x9aeb50f542050172359a0e1a25a9933bc8c01259",
        "_blank"
      );
    } else if (id.includes("2")) {
      window.open(
        "https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658E64FFcF40D240A43D52CA9342140316Ae44fA",
        "_blank"
      );
    } else {
      window.open(
        "https://ascendex.com/en/staking/investment-product-details/OIN-S",
        "_blank"
      );
    }
  };
  return (
    <Container>
      <Flex
        w="91%"
        align="center"
        color="white"
        bg="rgba(255, 255, 255, 0.05)"
        fontWeight="bold"
      >
        <p style={{ marginLeft: `2vw` }}>Exchanges</p>
      </Flex>
      <Grid
        w="90%"
        bg="transparent"
        borderRadius="10px"
        height="15vw"
        gridTemplateColumns="repeat(3,1fr)"
        gridAutoRows="100%"
      >
        {MobilePartnershipData.Exchanges.map((item) => {
          return (
            <Logo
              src={item.logo}
              alt=""
              key={item.id}
              transparent={item.transparent}
              onClick={() => exchangeHandler(item.id)}
            />
          );
        })}
      </Grid>
      <Flex
        w="91%"
        align="center"
        color="white"
        bg="rgba(255, 255, 255, 0.05)"
        fontWeight="bold"
        mt="2rem"
      >
        <p style={{ marginLeft: `2vw` }}>Investors</p>
      </Flex>
      <Grid
        w="90%"
        bg="transparent"
        borderRadius="10px"
        height="45vw"
        gridTemplateColumns="repeat(4,1fr)"
        gridAutoRows="33.3%"
      >
        {MobilePartnershipData.Investors.map((item) => {
          return (
            <Logo
              src={item.logo}
              alt=""
              key={item.id}
              transparent={item.transparent}
            />
          );
        })}
      </Grid>
      <Flex
        w="91%"
        align="center"
        color="white"
        bg="rgba(255, 255, 255, 0.05)"
        fontWeight="bold"
        mt="2rem"
      >
        <p style={{ marginLeft: `2vw` }}>Ecosystem</p>
      </Flex>
      <Grid
        w="90%"
        bg="transparent"
        borderRadius="10px"
        height="70vw"
        gridTemplateColumns="repeat(4,1fr)"
        gridAutoRows="20%"
      >
        {MobilePartnershipData.Ecosystem.map((item) => {
          return (
            <Logo
              src={item.logo}
              alt=""
              key={item.id}
              transparent={item.transparent}
            />
          );
        })}
      </Grid>
    </Container>
  );
}
