import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  margin-top: 80px;
  width: 100%;
`;

const Container = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 1025px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
  @media (max-width: 1025px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const Text = styled.div`
  font-size: 22px;
  margin-top: 30px;
  @media (max-width: 1025px) {
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    font-weight: 500;
  }
`;

const Table = styled.table`
  margin: auto;
  margin-top: 50px;
  border: 1px solid grey;
  border-collapse: collapse;
  @media (max-width: 1025px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const TH = styled.th`
  padding: 5px 10px 5px 10px;
  border: 1px solid grey;
  border-collapse: collapse;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 1025px) {
    font-size: 1.5vw;
  }
  @media (max-width: 600px) {
    font-size: 2vw;
  }
`;

const TD = styled.td`
  text-align: center;
  padding: 5px 10px 5px 10px;
  border: 1px solid grey;
  border-collapse: collapse;
  font-size: 20px;
  @media (max-width: 1025px) {
    font-size: 1.5vw;
  }
  @media (max-width: 600px) {
    font-size: 2vw;
  }
`;

const UL = styled.ul`
  list-style: none;
  padding: 0;
`;

const BBScope = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Program Scope</Title>
        <Text>
          The bug bounty program includes all contracts and front ends in the
          OIN protocol smart contract. In general: XSS cross-site, SQL
          injection, XXE, command execution, file inclusion, arbitrary file
          operation, privilege escalation, file upload, logic vulnerability,
          stack overflow, heap overflow, memory corruption, exception handling,
          git-shell, denial of service, CRLF Injection, SSRF, meltdown,
          disclosure of sensitive information, etc.
        </Text>
        <Title style={{ marginTop: "80px" }}>Rating Rules</Title>
        <Table>
          <thead>
            <tr>
              <TH>Types of attack</TH>
              <TH>Name</TH>
              <TH>Score</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>Method of attack</TD>
              <TD>
                <UL>
                  <li>Long Distance Attack (N)</li>
                  <li>Adjacent Node (A)</li>
                  <li>Local Attack (L)</li>
                  <li>Physics Node (P)</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>0.8</li>
                  <li>0.6</li>
                  <li>0.5</li>
                  <li>0.2</li>
                </UL>
              </TD>
            </tr>
            <tr>
              <TD>Complexity of attack</TD>
              <TD>
                <UL>
                  <li>Low(L)</li>
                  <li>High(H)</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>0.4</li>
                  <li>0.7</li>
                </UL>
              </TD>
            </tr>
            <tr>
              <TD>Permission requirements</TD>
              <TD>
                <UL>
                  <li>None(N)</li>
                  <li>Low(L)</li>
                  <li>High(H)</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>0.2</li>
                  <li>0.6</li>
                  <li>0.8</li>
                </UL>
              </TD>
            </tr>
            <tr>
              <TD>User interaction</TD>
              <TD>
                <UL>
                  <li>No Need(N)</li>
                  <li>Pass(P)</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>0.8</li>
                  <li>0.6</li>
                </UL>
              </TD>
            </tr>
            <tr>
              <TD>
                <UL>
                  <li>Confidentiality</li>
                  <li>Integrity</li>
                  <li>Availability</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>High(H)</li>
                  <li>Low(L)</li>
                  <li>None(N)</li>
                </UL>
              </TD>
              <TD>
                <UL>
                  <li>0.5</li>
                  <li>0.2</li>
                  <li>0.0</li>
                </UL>
              </TD>
            </tr>
            <tr>
              <TD>Sphere of influence</TD>
              <TD></TD>
              <TD>Depends on real effect</TD>
            </tr>
          </tbody>
        </Table>
        <Text>
          <div style={{ fontStyle: "italic" }}>
            <strong>Note:</strong> The highest proportion is sphere of
            influence.
          </div>
          <br />
          <br />
          <strong>Example:</strong> A tester submits a low-severity
          vulnerability; the tester's score is the sum of the basic score and
          the scope of impact.
        </Text>
        <Table>
          <thead>
            <tr>
              <TH>Attack's approach</TH>
              <TH>Attack's complexity</TH>
              <TH>Authority</TH>
            </tr>
            <tr>
              <TD>N</TD>
              <TD>L</TD>
              <TD>L</TD>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TH>User interaction</TH>
              <TH>Applicability</TH>
              <TH>Influence scope</TH>
            </tr>
            <tr>
              <TD>P</TD>
              <TD>L</TD>
              <TD>1</TD>
            </tr>
          </tbody>
        </Table>

        <Text style={{ marginLeft: "3vw" }}>
          <span style={{ fontStyle: "italic" }}>Basic score</span> : 0.8 + 0.4 +
          0.6 + 0.6 + 0.2 = 2.6 <br />
          <span style={{ fontStyle: "italic" }}>Sphere of influence</span> : 1
          point <br />
          <span style={{ fontStyle: "italic" }}>Total</span> : 2.6 + 1 = 3.6
          <br />
        </Text>
        <Text>
          <span style={{ fontStyle: "italic" }}>
            Findings: The system is rated as low risk, and the corresponding
            reward can be obtained.
          </span>
          <br />
          <br />
          According to the above scores, vulnerabilities can be divided into:
          <br />
          <ul>
            <li>Critical (9.6~10.0)</li>
            <li>High (7.0~9.5)</li>
            <li>Medium (4.0~6.9)</li>
            <li>Low (0.1~3.9)</li>
            <li>void (0.0)</li>
          </ul>
          <strong style={{ marginTop: "30px" }}>
            Corresponding reward range (For Reference):
          </strong>
        </Text>
        <Table style={{ width: "100%", marginTop: "20px" }}>
          <thead>
            <tr>
              <TH>Critical</TH>
              <TH>High</TH>
              <TH>Medium</TH>
              <TH>Low</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>$10,000 ~ $150,000</TD>
              <TD>$3000 ~ $10,000</TD>
              <TD>$1,000 ~ $3,000</TD>
              <TD>$200 ~ $1,000</TD>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Container style={{ marginTop: "50px" }}>
        <Title>Please Note:</Title>
        <Text>
          <ul>
            <li style={{ marginTop: "20px" }}>
              {" "}
              Questions unrelated to the report will not be answered via the
              email above
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              Discoveries based on the severity and exploitability of the
              discovery will be paid with proportionate rewards
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              All reward determinations, including eligibility and payment
              amount, are made at OIN's sole discretion
            </li>
            <li style={{ marginTop: "20px" }}>
              {" "}
              OIN reserves the right to reject submissions and change the terms
              and conditions of this program at any time.
            </li>
          </ul>
        </Text>
      </Container>
    </Wrapper>
  );
};

export default BBScope;
