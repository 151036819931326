import Ad1 from "../assets/icons/icon1.png";
import Ad2 from "../assets/icons/icon2.png";
import Ad3 from "../assets/icons/icon3.png";
import Ad4 from "../assets/icons/icon4.png";
import Ad5 from "../assets/icons/icon5.png";
import Team1 from "../assets/team/1.png";
import Team2 from "../assets/team/2.png";
import Team3 from "../assets/team/3.png";
import Team4 from "../assets/team/4.png";
import Team6 from "../assets/team/6.png";
import Team7 from "../assets/team/7.png";
import Team8 from "../assets/team/8.png";
import Team9 from "../assets/team/9.png";
import Telegram from "../assets/icons/Telegram.png";
import Twitter from "../assets/icons/Twitter.png";
import Medium from "../assets/icons/Medium.png";
import Discord from "../assets/icons/Discord.png";
import Github from "../assets/icons/Github.png";
import Ec1 from "../assets/partnership/ecosystem/1.png";
import Ec2 from "../assets/partnership/ecosystem/2.png";
import Ec3 from "../assets/partnership/ecosystem/3.png";
import Ec4 from "../assets/partnership/ecosystem/4.png";
import Ec5 from "../assets/partnership/ecosystem/5.png";
import Ec6 from "../assets/partnership/ecosystem/6.png";
import Ec7 from "../assets/partnership/ecosystem/7.png";
import Ec8 from "../assets/partnership/ecosystem/8.png";
import Ec9 from "../assets/partnership/ecosystem/9.png";
import Ec10 from "../assets/partnership/ecosystem/10.png";
import Ec11 from "../assets/partnership/ecosystem/11.png";
import Ec12 from "../assets/partnership/ecosystem/12.png";
import Ec13 from "../assets/partnership/ecosystem/13.png";
import Ec14 from "../assets/partnership/ecosystem/14.png";
import Ec15 from "../assets/partnership/ecosystem/15.png";
import Ec16 from "../assets/partnership/ecosystem/16.png";
import Ec17 from "../assets/partnership/ecosystem/17.png";
import Ec18 from "../assets/partnership/ecosystem/18.png";
import Ec19 from "../assets/partnership/ecosystem/19.png";
import Investor1 from "../assets/partnership/investors/1.png";
import Investor2 from "../assets/partnership/investors/2.png";
import Investor3 from "../assets/partnership/investors/3.png";
import Investor4 from "../assets/partnership/investors/4.png";
import Investor5 from "../assets/partnership/investors/5.png";
import Investor6 from "../assets/partnership/investors/6.png";
import Investor7 from "../assets/partnership/investors/7.png";
import Investor8 from "../assets/partnership/investors/8.png";
import Investor9 from "../assets/partnership/investors/9.png";
import Investor10 from "../assets/partnership/investors/10.png";
import Ex3 from "../assets/partnership/exchanges/Ascend.png";
import Ex1 from "../assets/partnership/exchanges/Uniswap.png";
import Ex2 from "../assets/partnership/exchanges/Pancakeswap.png";

export const NavData = [
  {
    title: "OINDAO",
    id: "Nav-2",
    link: "https://v3.oin.finance/",
  },
  {
    title: "Staking",
    id: "Nav-3",
    dropdown: true,
  },
  {
    title: "Bridge",
    id: "Nav-4",
    dropdown: true,
  },
  {
    title: "Docs",
    id: "Nav-1",
    dropdown: true,
  },
  {
    title: "Community",
    id: "Nav-5",
    dropdown: true,
  },
  {
    title: "Bug Bounty",
    id: "Nav-6",
    link: "/bug_bounty",
  },
];

export const MobileNav = [
  {
    title: "Docs",
    id: "Nav-1",
    dropdown: [
      {
        title: "OINDAO Docs",
        id: "Sub1-1",
        link: "https://oin-finance.gitbook.io/925/",
      },
    ],
    border: true,
    first: true,
  },
  {
    title: "OINDAO",
    id: "Nav-2",
    border: true,
    link: "https://v3.oin.finance/",
  },
  {
    title: "Oinstake",
    id: "Nav-3",
    border: true,
    link: "https://dao.oin.finance/",
  },
  {
    title: "Uniswap",
    border: true,
    id: "Nav-6",
    link: " https://oin.finance/page/",
  },
  {
    title: "Pancake",
    border: true,
    id: "Nav-7",
    link: "https://oin.finance/bscpage/",
  },
  {
    title: "BSC",
    id: "Nav-4",
    border: true,
    link: "https://bg.oin.finance/",
  },
  {
    title: "NEAR",
    id: "Nav-8",
    border: true,
    link: "https://ethereum.bridgetonear.org/",
  },
  {
    title: "Harmony",
    id: "Nav-9",
    border: true,
    link: "https://bridge.harmony.one/erc20",
  },
  {
    title: "Bug Bounty",
    id: "Nav-10",
    border: true,
    link: "#/bug_bounty",
  },
  {
    title: "Community",
    id: "Nav-5",
    dropdown: [
      {
        title: "Twitter",
        id: "Sub2-4",
        link: "https://twitter.com/financeoin?s=21",
      },
      { title: "Medium", id: "Sub2-1", link: "https://medium.com/oin-finance" },
      { title: "Telegram", id: "Sub2-2", link: "https://t.me/OIN_Finance_Eng" },
      {
        title: "Discord",
        id: "Sub2-3",
        link: "https://discord.com/invite/wHgckxGYje",
      },
    ],
    border: false,
    last: true,
  },
  
];

export const ConverData = {
  Title: "A robust turnkey",
  Title2: "stablecoin issuance platform ",
  Title3: "built for the multi-chain universe",
  Issued: "Issued",
  Issued2: "Stable Coins",
  Locked: "Total",
  Locked2: "Value Locked",
  Button: "Mint NOW",
  MobileIssued: "Issued Stable Coins",
  MobileIssued2: "Total Value Locked",
};

export const AboutData = {
  Title: "OINDAO Staking + Mining",
  Content: "is a multi-chain decentralized stablecoin issuance platform.",
  Content2:
    "OIN aims at building DeFi infrastructure and providing DeFi functionalities for all crypto projects, large or small, to issue their own branded Stablecoins that are collateralized by their own tokens.",
  Content3:
    "OIN is currently on Ethereum, but will gradually expand to other ecosystem, such as Avalanche, Near, Elrond and Harmony. OIN’s ultimate vision is to eventually build a multichain stablecoin universe.",
};

export const AdvData = [
  {
    id: "Ad-1",
    icon: Ad1,
    title: "Better Liquidity",
    description:
      "Through our platform, you can instantly redeem your stablecoin for the underlying token!",
  },
  {
    id: "Ad-2",
    icon: Ad2,
    title: "Censorship Resistant",
    description:
      "As everything is done on-chain through smart contracts, it holds all the advantages of a decentralized blockchain",
  },
  {
    id: "Ad-3",
    icon: Ad3,
    title: "Volatility  Resistant",
    description:
      "Our stablecoins are exactly stable. Leverage your coins to mint stablecoins through our OINDAO",
  },
  {
    id: "Ad-4",
    icon: Ad4,
    title: "Multichain  Governance",
    description:
      " Our platform will give multiple methods of governance through the different partners and their blockchains through the OINDAO",
  },
  {
    id: "Ad-5",
    icon: Ad5,
    title: "Multicoin  Farming",
    description:
      "Our OinStake contract of the OINDAO is able to reward multiple tokens to the individual collateralization pools!",
  },
];

export const TeamData1 = [
  {
    id: "Team-1",
    Name: "Richie Li",
    Role: "Co-founder",
    Icon: Team2,
  },
  {
    id: "Team-2",
    Name: "Amo Huang",
    Role: "Tech Lead",
    Icon: Team7,
  },
  {
    id: "Team-3",
    Name: "Daniel Tan",
    Role: "BD Lead",
    Icon: Team3,
  },
  {
    id: "Team-8",
    Name: "Erdil Özmen",
    Role: "Community Lead",
    Icon: Team8,
  },
];

export const TeamData2 = [
  {
    id: "Team-4",
    Name: "Daniel Kwak",
    Role: "Marketing Director",
    Icon: Team6,
  },
  {
    id: "Team-6",
    Name: "Don Xu",
    Role: "Technology Developer",
    Icon: Team4,
  },
  {
    id: "Team-7",
    Name: "Wei Liu",
    Role: "Technology Developer",
    Icon: Team1,
  },
  {
    id: "Team-9",
    Name: "Danny J.C.",
    Role: "Consultant",
    Icon: Team9,
  },
];

export const TeamDataMobile = [
  {
    id: "Mb-1",
    Name: "Richie Li",
    Role: "Co-founder",
    Icon: Team2,
  },
  {
    id: "Mb-2",
    Name: "Amo Huang",
    Role: "Tech Lead",
    Icon: Team7,
  },
  {
    id: "Mb-3",
    Name: "Daniel Tan",
    Role: "BD Lead",
    Icon: Team3,
  },
  {
    id: "Mb-4",
    Name: "Daniel Kwak",
    Role: "Marketing Director",
    Icon: Team6,
  },
  {
    id: "Mb-6",
    Name: "Don Xu",
    Role: "Tech Developer",
    Icon: Team4,
  },
  {
    id: "Mb-7",
    Name: "Wei Liu",
    Role: "Tech Developer",
    Icon: Team1,
  },
  {
    id: "Mb-9",
    Name: "Danny J.C.",
    Role: "Consultant",
    Icon: Team9,
  },
  {
    id: "Mb-8",
    Name: "Erdil Özmen",
    Role: "Community Lead",
    Icon: Team8,
  },
];

export const FootData = {
  Update: "Stay updated on the latest news from OIN Finance and more!",
  CopyRight: "© Copyright 2020 OIN Finance",
  Social: [
    { icon: Telegram, link: "https://t.me/OIN_Finance_Eng" },
    { icon: Twitter, link: "https://twitter.com/FinanceOin" },
    { icon: Medium, link: "https://medium.com/oin-finance" },
    { icon: Discord, link: "https://discord.com/invite/wHgckxGYje" },
    { icon: Github, link: "https://github.com/oinfinance/" },
  ],
  NavItem: [
    { title: "About", link: "Scroll-1" },
    { title: "Advantages", link: "Scroll-2" },
    { title: "Roadmap", link: "Scroll-3" },
    { title: "Exchange & Ecosystems", link: "Scroll-4" },
    { title: "Partnerships", link: "Scroll-6" },
  ],
};

export const RoadMapData = {
  Pone: ["Multi-chain strategy"],

  Ptwo: [
    "Dynamic collateral and redemption protocol updated",
    "System UI and UX upgrade",
    "Mobile UI upgrade",
    "Mining module architecture upgrade",
    "OINDAO live on multiple ecosystems",
  ],

  Pthree: [
    "OINDAO v3 available on Ethereum Virtual Machine",
    "Planning and revision of liquidation options",
    "Liquidation architecture design update",
    "Stability pool module implementation",
    "Implementation of redistribution mechanism module",
    "Recovery mode mechanism implementation",
    "Development of multi-coin collateralization and liquidation methods",
  ],
  Pfour: [
    "Multi-chain OINDAO v3 version upgrade",
    "Front-end optimization and upgrade",
    "Publish SDK kit",
    "Support contract data debugging",
    "Layer2 network evaluation and implementation",
  ],

  Pfive: [
    "Governance System Deployment",
    "Community OIP (Organization Improvement Plan) proposal system project setup",
    "Governance proposal review system",
    "Automatic execution module of OIP management parameter",
  ],
};

export const PartnershipData = {
  Exchanges: [
    { id: "Ex-1", logo: Ex1, transparent: 0 },
    { id: "Ex-2", logo: Ex2, transparent: 1 },
    { id: "Ex-3", logo: Ex3, transparent: 0 },
  ],
  Ecosystem: [
    { id: "Eco-1", logo: Ec6, transparent: 0 },
    { id: "Eco-2", logo: Ec7, transparent: 1 },
    { id: "Eco-3", logo: Ec3, transparent: 0 },
    { id: "Eco-4", logo: Ec4, transparent: 1 },
    { id: "Eco-5", logo: Ec2, transparent: 0 },
    { id: "Eco-6", logo: Ec1, transparent: 1 },
    { id: "Eco-7", logo: Ec5, transparent: 0 },
    { id: "Eco-8", logo: Ec8, transparent: 1 },
    { id: "Eco-9", logo: Ec9, transparent: 0 },
    { id: "Eco-10", logo: Ec10, transparent: 1 },
    { id: "Eco-11", logo: Ec11, transparent: 0 },
    { id: "Eco-12", logo: Ec12, transparent: 1 },
    { id: "Eco-13", logo: Ec13, transparent: 0 },
    { id: "Eco-14", logo: Ec14, transparent: 1 },
    { id: "Eco-15", logo: Ec15, transparent: 0 },
    { id: "Eco-16", logo: Ec16, transparent: 1 },
    { id: "Eco-17", logo: Ec17, transparent: 0 },
    { id: "Eco-18", logo: Ec18, transparent: 1 },
    { id: "Eco-19", logo: Ec19, transparent: 0 },
  ],
  Investors: [
    { id: "In-1", logo: Investor1, transparent: 0 },
    { id: "In-3", logo: Investor3, transparent: 1 },
    { id: "In-4", logo: Investor4, transparent: 1 },
    { id: "In-5", logo: Investor5, transparent: 0 },
    { id: "In-6", logo: Investor6, transparent: 1 },
    { id: "In-7", logo: Investor7, transparent: 0 },
    { id: "In-8", logo: Investor8, transparent: 1 },
    { id: "In-10", logo: Investor10, transparent: 0 },
  ],
};

export const MobilePartnershipData = {
  Exchanges: [
    { id: "Ex-1", logo: Ex1, transparent: 0 },
    { id: "Ex-2", logo: Ex2, transparent: 1 },
    { id: "Ex-3", logo: Ex3, transparent: 0 },
  ],
  Ecosystem: [
    { id: "Eco-1", logo: Ec6, transparent: 0 },
    { id: "Eco-2", logo: Ec7, transparent: 1 },
    { id: "Eco-3", logo: Ec3, transparent: 0 },
    { id: "Eco-4", logo: Ec4, transparent: 1 },
    { id: "Eco-5", logo: Ec2, transparent: 0 },
    { id: "Eco-6", logo: Ec1, transparent: 1 },
    { id: "Eco-7", logo: Ec5, transparent: 0 },
    { id: "Eco-8", logo: Ec8, transparent: 0 },
    { id: "Eco-9", logo: Ec9, transparent: 0 },
    { id: "Eco-10", logo: Ec10, transparent: 1 },
    { id: "Eco-11", logo: Ec11, transparent: 0 },
    { id: "Eco-12", logo: Ec12, transparent: 1 },
    { id: "Eco-13", logo: Ec13, transparent: 1 },
    { id: "Eco-14", logo: Ec14, transparent: 0 },
    { id: "Eco-15", logo: Ec15, transparent: 1 },
    { id: "Eco-16", logo: Ec16, transparent: 0 },
    { id: "Eco-17", logo: Ec17, transparent: 0 },
    { id: "Eco-18", logo: Ec18, transparent: 1 },
    { id: "Eco-19", logo: Ec19, transparent: 0 },
  ],
  Investors: [
    { id: "In-1", logo: Investor1, transparent: 0 },
    { id: "In-2", logo: Investor2, transparent: 1 },
    { id: "In-3", logo: Investor3, transparent: 0 },
    { id: "In-4", logo: Investor4, transparent: 1 },
    { id: "In-5", logo: Investor5, transparent: 1 },
    { id: "In-6", logo: Investor6, transparent: 0 },
    { id: "In-7", logo: Investor7, transparent: 1 },
    { id: "In-8", logo: Investor8, transparent: 0 },
    { id: "In-9", logo: Investor9, transparent: 0 },
    { id: "In-10", logo: Investor10, transparent: 1 },
  ],
};
