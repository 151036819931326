import { createGlobalStyle } from "styled-components";

const BugBountyStyle = createGlobalStyle`
    html, body{
        width:100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
        overflow-x: hidden;
        line-height: 33px;
        @media(max-width: 1025px){
            line-height: 23px;
        }
        @media(max-width: 600px){
            line-height: 30px;
        }
    }
`;

export default BugBountyStyle;
