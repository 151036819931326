
import React from "react";
import Nav from "../../components/Nav/Nav";
import BBBanner from "../../components/BBBanner/BBBanner";
import BBAbout from "../../components/BBAbout/BBAbout";
import BBRewards from "../../components/BBRewards/BBRewards";
import BBScope from "../../components/BBScope/BBScope";
import BBTerms from "../../components/BBTerms/BBTerms";
import BugBountyStyle from "../../BugBountyStyle";
import BBFoot from "../../components/BBFooter/BBFoot";
function BugBounty() {
  return (
    <>
        <BugBountyStyle />
        <Nav />
        <BBBanner />
        <BBAbout />
        <BBRewards />
        <BBTerms />
        <BBScope />
        <BBFoot />
    </>
  );
}

export default BugBounty;
