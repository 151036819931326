import React, { useState } from "react";
import { Title } from "../Cover/Cover";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { RoadMapData } from "../../constants/Data";
import TimeImg from "../../assets/roadmap/Timeline.png";
import Spot from "../../assets/roadmap/spot-1.png";
import SpotL from "../../assets/roadmap/spot-2.png";
import Left from "../../assets/roadmap/left-1.png";
import LeftL from "../../assets/roadmap/left-2.png";
import Right from "../../assets/roadmap/right-1.png";
import RightL from "../../assets/roadmap/right-2.png";
import Line1 from "../../assets/roadmap/L1.png";
import Line3 from "../../assets/roadmap/L3.png";
import Line4 from "../../assets/roadmap/L4.png";
import Line5 from "../../assets/roadmap/L5.png";
import MobileRoadMap from "./MobileRoadMap";
import { Gradient } from "../About/About";

const RoadTitle = styled(Title)`
  width: max-content;
  height: max-content;
  position: relative;
  @media (min-width: 600px) and (max-width: 1366px) {
    font-size: 4vw;
  }
  @media (max-width: 600px) {
    font-size: 8vw;
  }
`;
const Container = styled.div`
  width: 100%;
  margin-top: 15vw;
  @media (max-width: 600px) {
    display: none;
  }
`;

const TimeStamp = styled(LazyLoadImage)`
  position: absolute;
  width: 2vw;
  height: 2vw;
  top: 6.3vw;
  left: 15vw;
  cursor: pointer;
`;

const Phase = styled.div`
  position: absolute;
  top: 9.5vw;
  left: 14vw;
  font-size: 1vw;
  font-weight: bold;
  letter-spacing: 0.15rem;
  color: white;
`;

const PhaseB = styled(Phase)`
  top: 16.2vw;
  left: 29vw;
`;

const PhaseC = styled(Phase)`
  top: 15vw;
  left: 47vw;
`;

const PhaseD = styled(Phase)`
  top: 7.2vw;
  left: 62vw;
`;

const PhaseE = styled(Phase)`
  top: 5.5vw;
  left: 79vw;
`;

const TimeStampB = styled(TimeStamp)`
  top: 13.2vw;
  left: 30vw;
`;

const TimeStampC = styled(TimeStamp)`
  top: 11.8vw;
  left: 48vw;
`;

const TimeStampD = styled(TimeStamp)`
  top: 4.4vw;
  left: 63vw;
`;

const TimeStampE = styled(TimeStamp)`
  top: 2.4vw;
  left: 80vw;
`;

const Timeline = styled.div`
  width: 100%;
  background-image: url(${TimeImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20vw;
  position: relative;
`;

const Control = styled.div`
  display: flex;
  justify-content: center;
`;

const ControlImg = styled(LazyLoadImage)`
  height: 2vw;
  width: 2vw;
  cursor: pointer;
  margin: 0 1vw;
`;

const Typography = styled.div`
  position: absolute;
  color: white;
  font-size: 1vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  left: 32vw;
  top: -15.5vw;
`;

const LineA = styled(LazyLoadImage)`
  height: 18vw;
  width: 5vw;
  position: absolute;
  left: 16vw;
  top: -11vw;
`;

const LineB = styled(LineA)`
  left: 31vw;
  top: -4vw;
`;
const LineC = styled(LineA)`
  left: 39vw;
  top: -8vw;
  height: 21vw;
  width: 10vw;
`;
const LineD = styled(LineA)`
  left: 48.7vw;
  top: -9vw;
  height: 14vw;
  width: 15vw;
`;
const LineE = styled(LineA)`
  left: 31vw;
  top: -16vw;
  width: 50vw;
  height: 20vw;
`;

const BulletPoint = styled.ul`
  color: white;
  position: absolute;
  font-size: 1vw;
  left: 21vw;
  top: -11vw;
`;

const BulletPointB = styled(BulletPoint)`
  left: 36vw;
  top: -5vw;
`;
const BulletPointC = styled(BulletPoint)`
  left: 39vw;
  top: -9vw;
`;
const BulletPointD = styled(BulletPoint)`
  left: 49vw;
  top: -10vw;
`;

export default function RoadMap() {
  const [active, setActive] = useState({
    first: true,
    second: false,
    third: false,
    forth: false,
    fifth: false,
  });

  const [hover, setHover] = useState({ left: false, right: false });

  const ClickHandler = (index) => {
    index === "A"
      ? setActive({
          first: true,
          second: false,
          third: false,
          forth: false,
          fifth: false,
        })
      : index === "B"
      ? setActive({
          first: false,
          second: true,
          third: false,
          forth: false,
          fifth: false,
        })
      : index === "C"
      ? setActive({
          first: false,
          second: false,
          third: true,
          forth: false,
          fifth: false,
        })
      : index === "D"
      ? setActive({
          first: false,
          second: false,
          third: false,
          forth: true,
          fifth: false,
        })
      : setActive({
          first: false,
          second: false,
          third: false,
          forth: false,
          fifth: true,
        });
  };
  const switchLft = () => {
    const arr = Object.values(active);
    const index = arr.indexOf(true);
    let obj = {};
    index === 1
      ? obj = {
          first: true,
          second: false,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 2
      ? obj = {
          first: false,
          second: true,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 3
      ? obj = {
          first: false,
          second: false,
          third: true,
          forth: false,
          fifth: false,
        }
      : index === 4
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: true,
          fifth: false,
        }
      : obj = {};
      setActive(obj);
  };
  const switchRht = () => {
    const arr = Object.values(active);
    const index = arr.indexOf(true);
    let obj = {};
    index === 0
      ? obj = {
          first: false,
          second: true,
          third: false,
          forth: false,
          fifth: false,
        }
      : index === 1
      ? obj = {
          first: false,
          second: false,
          third: true,
          forth: false,
          fifth: false,
        }
      : index === 2
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: true,
          fifth: false,
        }
      : index === 3
      ? obj = {
          first: false,
          second: false,
          third: false,
          forth: false,
          fifth: true,
        }
      : obj = {};
      setActive(obj);
  };
  return (
    <>
      <RoadTitle id="Scroll-3" style={{ marginTop: `20vw` }}>
        <Gradient />
        Roadmap
      </RoadTitle>
      <MobileRoadMap
        hover={hover}
        setHover={setHover}
        active={active}
        setActive={setActive}
        ClickHandler={ClickHandler}
      />
      <Container>
        <Timeline>
          {active.first ? (
            <>
              <LineA src={Line1} alt="" />
              <BulletPoint>
                {RoadMapData.Pone.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </BulletPoint>
            </>
          ) : active.second ? (
            <>
              <LineB src={Line1} alt="" />
              <BulletPointB>
                {RoadMapData.Ptwo.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </BulletPointB>
            </>
          ) : active.third ? (
            <>
              <LineC src={Line3} alt="" />
              <BulletPointC>
                {RoadMapData.Pthree.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </BulletPointC>
            </>
          ) : active.forth ? (
            <>
              <LineD src={Line4} alt="" />
              <BulletPointD>
                {RoadMapData.Pfour.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </BulletPointD>
            </>
          ) : (
            <>
              <LineE src={Line5} alt="" />
              <Typography>
                {RoadMapData.Pfive.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </Typography>
            </>
          )}

          {!active.first ? (
            <TimeStamp src={Spot} alt="" onClick={() => ClickHandler("A")} />
          ) : (
            <TimeStamp src={SpotL} alt="" />
          )}
          <Phase>2021 Q2</Phase>
          {!active.second ? (
            <TimeStampB src={Spot} alt="" onClick={() => ClickHandler("B")} />
          ) : (
            <TimeStampB src={SpotL} alt="" />
          )}
          <PhaseB>2021 Q3</PhaseB>

          {!active.third ? (
            <TimeStampC src={Spot} alt="" onClick={() => ClickHandler("C")} />
          ) : (
            <TimeStampC src={SpotL} alt="" />
          )}
          <PhaseC>2021 Q4</PhaseC>

          {!active.forth ? (
            <TimeStampD src={Spot} alt="" onClick={() => ClickHandler("D")} />
          ) : (
            <TimeStampD src={SpotL} alt="" />
          )}
          <PhaseD>2022 Q1</PhaseD>

          {!active.fifth ? (
            <TimeStampE src={Spot} alt="" onClick={() => ClickHandler("E")} />
          ) : (
            <TimeStampE src={SpotL} alt="" />
          )}
          <PhaseE>2022 Q2</PhaseE>
        </Timeline>
        <Control>
          {!hover.left ? (
            <ControlImg
              alt=""
              src={Left}
              onMouseEnter={() => setHover({ left: true })}
            />
          ) : (
            <ControlImg
              alt=""
              src={LeftL}
              onMouseLeave={() => setHover({ left: false })}
              onClick={switchLft}
            />
          )}
          {!hover.right ? (
            <ControlImg
              alt=""
              src={Right}
              onMouseEnter={() => setHover({ right: true })}
            />
          ) : (
            <ControlImg
              alt=""
              src={RightL}
              onMouseLeave={() => setHover({ right: false })}
              onClick={switchRht}
            />
          )}
        </Control>
      </Container>
    </>
  );
}
