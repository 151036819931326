import styled from "styled-components";
import React from "react"


const Wrapper = styled.div`
    margin-top: 100px;
    width: 100%;
`;

const Container = styled.div`
    width: 60%;
    margin: auto;
    @media(max-width: 1025px){
        width: 80%;
    }
    @media(max-width: 600px){
        width: 90%;
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-weight: 700;
    @media(max-width: 1025px){
        font-size: 25px;
    }
    @media(max-width: 600px){
        font-size: 30px;
    }
`;

const Text = styled.div`
    font-size: 22px;
    margin-top: 30px;
    @media(max-width: 1025px){
        font-size: 15px;
        font-weight: 500;
    }
    @media(max-width: 600px){
        font-size: 20px;
        font-weight: 500;
    }
`;
const BBAbout = () => {
    return (
        <Wrapper>
            <Container>
                <Title>
                    Introduction
                </Title>
                <Text>
                    Security is core to our values, and we value the contributions of good-faith hackers who help us maintain the highest level of ecosystem's security and safety. While the OIN protocol has undergone professional audits and formal verification, a bug bounty program adds another layer of checks in our never-ending pursuit of security.
                </Text>
            </Container>
        </Wrapper>
    );
}

export default BBAbout;